import HomeScreen from "./screens/HomeScreen";
import ReactGA from "react-ga";

const TRACKING_ID = "UA-262378881-1";
ReactGA.initialize(TRACKING_ID);


function App() {
  return (
    <div>
        <main className={'py-3'}>
           <HomeScreen />
        </main>
    </div>
  );
}

export default App;

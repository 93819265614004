import React from 'react';
import {Row, Col} from "react-bootstrap";

// import emailjs from '@emailjs/browser';

function Footer () {

    return (
        <div>
            <h4 style={{textAlign: 'center'}}>Our Location</h4>
            <iframe
                src={'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2805.5380831624975!2d-93.19294994866307!3d45.31775575112138!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52b3197a3e461697%3A0xcbfb1e9abc348751!2sHome%20Safety%20Solutions%20Inc!5e0!3m2!1sen!2sus!4v1676052346659!5m2!1sen!2sus'}
                // width="600"
                width={'100%'}
                // height="450"
                height={'450'}
                // style="border:0;"
                style={{border:'0'}}
                // allowFullScreen=""
                allowFullScreen={''}
                // loading="lazy"
                loading={'lazy'}
                // referrerPolicy="no-referrer-when-downgrade"
                referrerPolicy={'no-referrer-when-downgrade'}
                title={"Visit Us"}
            />
            <br /> <br /><br />
            <Row style={{padding:'0 30px'}}>
                <Col className={"text-left py-3"}>
                    MEMBERS OF:
                    <br />
                    • AARST (American Association of Radon Scientists and Technologists)
                    <br />
                    • Builder's Association of Minnesota
                    <br />
                    • National Association of Realtors
                    <br />
                    • Indoor Air Quality Association
                    <br />
                    • Minnesota Multifamily Association
                    <br />
                    • Minnesota Society of Home Inspectors
                    <br />
                    • VOC contractors for the US EPA St. Louis Park projects
                </Col>


                <Col style={{textAlign: 'right'}} className={"py-3"} >
                    Copyright &copy; Home Safety Solutions
                    < br />
                    HSS is a woman-owned company
                    < br />
                    Lic. # BC630387
                </Col>
            </Row>
        </div>
    )
}

export default Footer
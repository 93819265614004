import React, {useEffect, useRef, useState} from 'react';
import {Carousel, Col, Container, Form, Offcanvas, Row, Spinner} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Footer from "../components/Footer";
import {Parallax} from "react-parallax";
import Bounce from 'react-reveal/Bounce';
import axios from "axios";
import ReactGA from "react-ga";


const HomeScreen = () => {

    const [doneSubmitting, setDoneSubmitting] = useState(false)

    const reload=()=>window.location.reload();

    const [name, set_name] = useState('')
    const [message, set_message] = useState('')
    const [email, set_email] = useState('')
    const done = 'No'

    const handleContactClose = () => setShowContact(false);
    const handleContactOpen = () => setShowContact(true);

    const [step1, setStep1] = useState(false);

    const [radonSteps, setRadonSteps] = useState(false);
    const [moldSteps, setMoldSteps] = useState(false);

    const myRef = useRef(null);
    const VOCID = useRef(null);
    const RadonID = useRef(null);
    const Mold = useRef(null);
    const QualifcationsID = useRef(null);

    const [showContact, setShowContact] = useState(false);

    const QualificaitonsScroll = () => QualifcationsID.current.scrollIntoView();
    const VOCScroll = () => VOCID.current.scrollIntoView();

    const RadonScroll = () => RadonID.current.scrollIntoView();

    const MoldScroll = () => Mold.current.scrollIntoView();

    const handleStep1 = event => {
        // 👇️ toggle shown state
        setStep1(current => !current);
        // 👇️ or simply set it to true
        // setIsShown(true);
    };

    const showRadon = event => {
        setRadonSteps(current => !current);
    }

    const showMold = event => {
        setMoldSteps(current => !current);
    }

    const AddEmailContactForm = async () => {
        ReactGA.event({
            name: name,
            message: message,
            email: email,
        })

        if (name === '') {
            alert("Please enter your name")
        } else if (message === '') {
            alert("Please enter a message")
        } else if (email === '') {
            alert("Please enter your email")
        } else {
            setDoneSubmitting(true)
            let formField = new FormData()
            formField.append('name', name)
            formField.append('message', message)
            formField.append('email', email)
            formField.append('done', done)

            await axios({
                method: 'post',
                url: `https://homesafetysolutions.pythonanywhere.com/api/home_page_contact/create/`,
                // url: `http://127.0.0.1:8000/api/home_page_contact/create/`,
                data: formField,
            }).then(() => {
                alert("Thank you reaching out, we will contact you shortly")
            })
            setDoneSubmitting(false)
            reload()
        }
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    })

    return (
        <Container fluid style={{padding: '0', margin: '0'}}>
            <Container
                // fluid
            >
                <Carousel variant="dark">
                    <Carousel.Item interval={5500}>
                        <img
                            className="d-block w-100 homePageCarousel"
                            src={"FleetUseHeader.png"}
                            alt={"Group"}
                            loading={"lazy"}
                            // style={{width: 'auto', height: "auto"}}
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={5500}>
                        <img
                            className="d-block w-100 homePageCarousel"
                            src={"groupPicToUse.png"}
                            alt={"Group"}
                            loading={"lazy"}
                            // style={{width: 'auto', height: "auto"}}
                        />
                    </Carousel.Item>
                </Carousel>
            </Container>

            <div className="d-grid gap-2 sticky-top" style={{paddingTop: '10px', marginTop: '10px'}}>
                <Button variant="danger" style={{color: 'black', fontWeight: "bold"}} onClick={handleContactOpen}>
                    Contact Us
                </Button>
            </div>

            <Offcanvas show={showContact} onHide={handleContactClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Contact Us</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row style={{
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: 'bolder',
                        backgroundColor: 'black',
                        paddingTop: '30px'
                    }}>
                        <h3 style={{color: 'white'}}>Get in touch by email or call us for a free consultation!</h3>
                        <Col className={'ourMission'} style={{color: 'black', padding: '90px 0', margin: '0'}}>
                            <Form>
                                <Form.Group controlId={'name'}>
                                    <Form.Label>Name:</Form.Label>
                                    <Form.Control
                                        // aria-required={true}
                                        type={'text'}
                                        placeholder={"Your Name:"}
                                        name={'name'}
                                        value={name}
                                        onChange={(e) => set_name(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId={'message'}>
                                    <Form.Label>Your Message for us:</Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        placeholder={"Your Message For Us"}
                                        name={'message'}
                                        value={message}
                                        onChange={(e) => set_message(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group controlId={'email'}>
                                    <Form.Label>Your Email</Form.Label>
                                    <Form.Control
                                        type={'email'}
                                        placeholder={"Your Email:"}
                                        name={'email'}
                                        value={email}
                                        onChange={(e) => set_email(e.target.value)}
                                    />
                                </Form.Group>
                            </Form>
                            {
                                doneSubmitting
                                    ?
                                    (
                                        <span>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                Loading...
                            </span>
                                    )
                                    :
                                    (
                                        <div>
                                            <br/>
                                            <br/>
                                            <Button className={'btn btn-success'} onClick={AddEmailContactForm}>
                                                Send Message
                                            </Button>
                                        </div>
                                    )
                            }
                        </Col>

                        <Col className={'contactUsStyle'} style={{color: 'black', padding: '90px 0', margin: '0'}}>
                            <p>
                                Address:
                                3140 Viking Blvd NE <br/>
                                East Bethel, MN 55092 <br/>
                                <br/>
                                Call for a free Consultation:
                                763-434-3263 <br/>
                                <br/>
                                Email:
                                info@homesafetysolutionsinc.com<br/>
                            </p>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>


            <br/><br/>

            <div style={{textAlign: 'center'}}>
                <h2 className={"HomePageElementWhatWeDo"} style={{fontWeight: 'bolder', letterSpacing: '.2rem'}}>What We
                    DO</h2>
                <br/>
                <h4 className={"HomePageElementWhatWeDo"}>
                    RADON & VOC MITIGATION / MOLD REMEDIATION SPECIALISTS FOR COMMERCIAL | MULTI-FAMILY |
                    SINGLE-FAMILY PROPERTIES IN MINNESOTA AND SURROUNDING STATES. WE TAKE CARE OF YOUR INDOOR AIR
                    QUALITY.
                </h4>
                <div>
                    <Row>
                        <Col className={'ourMission HomePageElementWhatWeDo'}
                             style={{color: 'black', padding: '60px 0', margin: '60px 0'}}>
                            <h3 style={{color: 'black'}}>
                                Our Mission
                            </h3>
                            <h5 style={{color: 'black', padding: '0 15px'}} className={'HomePageElementWhatWeDo'}>
                                Home Safety Solution's Mission is to provide our customers with a superior quality
                                indoor environment
                                giving each the peace of mind that goes with exceptional workmanship and
                                professionalism.
                                New construction or a building with history, we have it covered.
                            </h5>
                        </Col>
                    </Row>
                </div>

                <h3>Our Services</h3>

                <Row style={{padding: '0 20px'}}>
                    <Col style={{
                        borderStyle: 'solid',
                        borderColor: 'lightgray',
                        borderRadius: '25px',
                        margin: '2px',
                        paddingTop: '14px',
                        paddingBottom: "15px"
                    }}>
                        <img
                            src={'Radon.jpg'}
                            alt={'Radon'}
                            style={{height: '220px', width: '220px'}}
                            loading={"lazy"}
                        />
                        <br/> <br/>
                        <h4 className={"HomePageElementWhatWeDo"}>Radon Testing & Mitigation</h4>
                        <p className={'pTagsForHomeScreen'}>
                            Radon is a naturally occurring radioactive gas which comes from radium found in the ground.
                            We at Home Safety Solutions(HSS) are certified in Radon mitigation and have been resolving
                            Radon through
                            mitigation since 2007 with 500+ single & multi-family homes, in addition to 650+ commercial
                            properties
                            treated by us.
                        </p>
                        <Button variant="primary" onClick={RadonScroll}>
                            Learn More
                        </Button>
                    </Col>

                    <Col style={{borderStyle: 'solid', borderColor: 'lightgray', borderRadius: '25px', margin: '2px'}}>
                        <img
                            src={'voc.jpg'}
                            alt={'VOC'}
                            style={{height: '220px', width: '220px'}}
                            loading={"lazy"}
                        />
                        <br/> <br/>
                        <h4 className={"HomePageElementWhatWeDo"}>VOC Testing & Mitigation</h4>
                        <p className={'pTagsForHomeScreen'}>
                            Volitile Organic Compounds (VOCs) are a large group of chemicals that are found in many
                            products
                            we use to build and maintain our homes. Once these chemicals are in our homes, they release
                            into the air we breath. We at HSS are VOC abatement professionals, and can mitigate any VOC
                            issues.
                        </p>
                        <Button variant="primary" onClick={VOCScroll}>
                            Learn More
                        </Button>
                    </Col>

                    <Col style={{
                        borderStyle: 'solid',
                        borderColor: 'lightgray',
                        borderRadius: '25px',
                        margin: '2px',
                        paddingTop: '14px'
                    }}>
                        <img
                            src={'mold.png'}
                            alt={'Mold'}
                            style={{height: '220px', width: '220px'}}
                            loading={"lazy"}
                        />
                        <h4 className={"HomePageElementWhatWeDo"} style={{marginTop: '13px'}}>Mold Testing &
                            Mitigation</h4>
                        <p className={'pTagsForHomeScreen'}>
                            Molds are very common in buildings and homes, it will grow in places with a lot of moisture.
                            Mold can cause a lot of damage to buildings, and some can cause health problems for people
                            who occupy these buildings.
                            We are able to provide Mold/Water intrusion measurement and remediation.
                        </p>
                        <Button variant="primary" onClick={MoldScroll}>
                            Learn More
                        </Button>
                    </Col>

                    <Col style={{borderStyle: 'solid', borderColor: 'lightgray', borderRadius: '25px', margin: '2px'}}>
                        <img
                            src={'mitigation.jpg'}
                            alt={'Mitigation'}
                            style={{height: '220px', width: '220px'}}
                            loading={"lazy"}
                        />
                        <br/> <br/>
                        <h4 className={"HomePageElementWhatWeDo"}>Mitigation & Qualifications</h4>
                        <p className={'pTagsForHomeScreen'}>
                            Any indoor air quality issues can cause health problems, so why chose us to help?
                            Home Safety Solutions, indoor air quality consultants are certified in Radon
                            Measurement and Mitigation,
                            Mold Certified water intrusion Surveyor and Measurement Technicians, and are VOC abatement
                            professionals.
                        </p>
                        <Button variant="primary" onClick={QualificaitonsScroll}>
                            Learn More
                        </Button>
                    </Col>

                    <Col style={{
                        borderStyle: 'solid',
                        borderColor: 'lightgray',
                        borderRadius: '25px',
                        margin: '2px',
                        paddingTop: '14px'
                    }}>
                        <img
                            src={'building.png'}
                            alt={'Buildings'}
                            style={{height: '220px', width: '220px'}}
                            loading={"lazy"}
                        />

                        <br/> <br/>
                        <h4 className={"HomePageElementWhatWeDo"}>Buildings</h4>
                        <p className={'pTagsForHomeScreen'}>We provide services for:</p>
                        <Row>
                            <Col>
                                <p className={'pTagsForHomeScreen'} style={{padding: '0', margin: '0'}}>
                                    Single Family
                                </p>
                                <p className={'pTagsForHomeScreen'} style={{padding: '0', margin: '0'}}>
                                    Multi-Family
                                </p>
                                <p className={'pTagsForHomeScreen'} style={{padding: '0', margin: '0'}}>
                                    Commercial
                                </p>
                            </Col>

                            <Col>
                                <p className={'pTagsForHomeScreen'} style={{padding: '0', margin: '0'}}>
                                    Hotels
                                </p>
                                <p className={'pTagsForHomeScreen'} style={{padding: '0', margin: '0'}}>
                                    Residential
                                </p>
                                <p className={'pTagsForHomeScreen'} style={{padding: '0', margin: '0'}}>
                                    Mobile Homes
                                </p>
                            </Col>
                        </Row>

                        <p className={'pTagsForHomeScreen'} style={{marginTop: '15px'}}>
                            New construction or existing, we have it covered.
                        </p>
                    </Col>
                </Row>

            </div>

            <br ref={RadonID}/> <br/>

            <div className={"radonProcess"} style={{
                textAlign: 'center',
                padding: '10px 0 40px 0',
                margin: '10px 0 10px 0',
                width: '100%',
                height: 'auto'
            }}>
                <br/>
                <h2 className={"homePageServicesText"}
                    style={{fontWeight: 'bolder', letterSpacing: '.2rem', paddingBottom: '2px'}}>
                    Radon Process
                </h2>

                <h4 className={"homePageServicesText"}>
                    As a company, Home Safety Solutions (HSS)
                    has completed more than 1,200 vapor intrusion projects. For Radon Mitigation we take these general
                    steps:
                    Testing | Sight walk & Mitigation Design | Installation | Follow Up Testing
                    <br/><br/>
                    Click below to learn more
                </h4>
                <br/>
                <br/>
                <Button onClick={showRadon}>Radon Process Steps</Button>
                <br/>
                <br/>
                <br/>
                {radonSteps && (
                    <div>
                        <Row>
                            <Col>
                                <h4 className={"homePageServicesText"}>Testing</h4>
                                <p className={"homePageServicesText"}>
                                    We test the air quality in single-family, multi-family, and commercial buildings to
                                    determine if radon levels are elevated and if so, by how much. We do this Radon
                                    testing with specialized equipment, the results of which are taken into account
                                    during
                                    the site walk and design of a mitigation system.
                                </p>
                            </Col>
                            <Col>
                                <img
                                    src={'Radon.png'}
                                    alt={'Buildings'}
                                    className={"landingPagePics"}
                                    loading={"lazy"}
                                    // style={{display: 'block', marginLeft: 'auto', marginRight: "auto",  maxHeight: '260px', maxWidth: '425px', width: 'auto', height: 'auto', borderRadius: '25px'}}
                                />
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <img
                                    src={'MitSiteWalk.PNG'}
                                    alt={'Buildings'}
                                    className={"landingPagePics"}
                                    loading={"lazy"}
                                    // style={{display: 'block', marginLeft: 'auto', marginRight: "auto",  maxHeight: '260px', maxWidth: '425px', width: 'auto', height: 'auto', borderRadius: '25px'}}
                                />
                            </Col>
                            <Col>
                                <h4 className={"homePageServicesText"}>Sight Walk & Design</h4>
                                <p style={{textAlign: "left"}} className={"homePageServicesText"}>
                                    Generally, our process consists of some of the following steps<br/>
                                    <br/>
                                    <h6>Site Walk: </h6>
                                    ⚪ Take a look at the exterior of the building and surrounding area to assess any
                                    potential entry points for radon. Identify any cracks, crevices, or other openings
                                    that could be used for radon entry. <br/>
                                    ⚪ Test the air quality throughout the building to determine the level of radon
                                    present. <br/>
                                    <br/>
                                    <h6>Design Process:</h6>
                                    ⚪ Develop a plan to reduce radon levels in the building. For this, we include
                                    sealing any cracks or crevices, installing a sub-slab depressurization system, and
                                    other potential mitigation techniques.<br/>
                                    ⚪ Install the necessary equipment and materials, following all local building codes
                                    and safety regulations.<br/>

                                </p>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <h4 className={"homePageServicesText"}>Installation</h4>
                                <p className={"homePageServicesText"}>
                                    After mitigation system design we work with building owners/residents to ensure
                                    installation of the system does not effect the residents.
                                    Installation time can vary, but we strive to complete it quickly and correctly so
                                    building residents can resume their daily lives without
                                    delay.
                                </p>
                            </Col>
                            <Col>
                                <img
                                    src={'MitRadon.PNG'}
                                    alt={'Buildings'}
                                    className={"landingPagePics"}
                                    loading={"lazy"}
                                    // style={{display: 'block', marginLeft: 'auto', marginRight: "auto",  maxHeight: '260px', maxWidth: '425px', width: 'auto', height: 'auto', borderRadius: '25px'}}
                                />
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <img
                                    src={'diagnostics.PNG'}
                                    alt={'Buildings'}
                                    className={"landingPagePics"}
                                    loading={"lazy"}
                                    // style={{display: 'block', marginLeft: 'auto', marginRight: "auto",  maxHeight: '260px', maxWidth: '425px', width: 'auto', height: 'auto', borderRadius: '25px'}}
                                />
                            </Col>
                            <Col>
                                <h4 className={"homePageServicesText"}>Follow Up Testing</h4>
                                <p className={"homePageServicesText"}>
                                    After Installation we test thoroughly to ensure the system is working optimally and
                                    complete a final inspection and/or paperwork for
                                    local and state agencies as required.
                                </p>
                            </Col>
                        </Row>

                    </div>
                )}
            </div>

            <br/> <br/>

            <Bounce left>
                <Row className={'homePageRow'} style={{
                    textAlign: 'center',
                    borderTop: '3px solid',
                    borderBottom: '3px solid',
                    marginBottom: '25px'
                }}>
                    <Col style={{paddingTop: '30px'}}>

                        <h4 className={'pTagsForHomeScreen'}>
                            Testing Is encouraged
                        </h4>

                        <br/>

                        <div className={'radonProcess'} style={{padding: '10px 0', borderRadius: '60px'}}>
                            <img
                                src={'testingIcon.png'}
                                alt={'Buildings'}
                                style={{height: '150px', width: 'auto', borderRadius: '60px', background: 'white'}}
                                loading={"lazy"}
                            />
                        </div>

                        <br/> <br/>
                        <p className={'pTagsForHomeScreen'}>
                            While only about 8% of the homes the U.S. have radon levels in living areas above the EPA
                            action level of 4 pCi/L,
                            these homes cannot be found without checking all homes. AARST recommends testing of homes,
                            schools,
                            public, and commercial buildings in high radon areas as the first priority. However, since
                            high radon
                            levels have been reported in all areas of the U.S. by radon testing companies, the second
                            priority is to encourage testing of all homes.
                        </p>
                    </Col>

                    <Col style={{paddingTop: '30px'}}>
                        <h4 className={'pTagsForHomeScreen'}>
                            How Does Radon Enter A Home
                        </h4>

                        <div className={'radonProcess'} style={{padding: '10px 0', borderRadius: '60px'}}>
                            <img
                                src={'EnteryIcon.png'}
                                alt={'Buildings'}
                                style={{height: '150px', width: 'auto', borderRadius: '60px', background: 'white'}}
                                loading={"lazy"}
                            />
                        </div>

                        <br/> <br/>
                        <p className={'pTagsForHomeScreen'}>
                            Radon, because it is a gas, is able to move through spaces in the soil or fill material
                            around a buildings foundation.
                            Minnesota buildings tend to operate under a negative pressure - this is especially true in
                            the lowest portions of the
                            home and during the heating season. This negative pressure acts as a vacuum (suction) that
                            pulls soil gases, including radon,
                            into the lower levels of the structure.
                        </p>
                    </Col>

                    <Col style={{paddingTop: '30px'}}>
                        <h4 className={'pTagsForHomeScreen'}>
                            Major Radon Entry Routes
                        </h4>
                        <br/>

                        <div className={'radonProcess'} style={{padding: '10px 0', borderRadius: '60px'}}>
                            <img
                                src={'buildingIcon.png'}
                                alt={'Buildings'}
                                style={{height: '150px', width: 'auto', borderRadius: '60px', background: 'white'}}
                                loading={"lazy"}
                            />
                        </div>
                        <br/> <br/>

                        <ul style={{textAlign: "left"}}>
                            <li className={'pTagsForHomeScreen'}>
                                Cracks in concrete slabs / pores and cracks in concrete blocks.
                            </li>
                            <li className={'pTagsForHomeScreen'}>
                                Spaces behind brick veneer walls that rest on uncapped hollow block foundations.
                            </li>
                            <li className={'pTagsForHomeScreen'}>
                                Exposed soil, as in a sump or crawl space
                            </li>
                            <li className={'pTagsForHomeScreen'}>
                                Weeping (drain) tile, if drained to an open sump.
                            </li>
                            <li className={'pTagsForHomeScreen'}>
                                Mortar joints / Floor-wall joints.
                            </li>
                            <li className={'pTagsForHomeScreen'}>
                                Loose fitting pipe penetrations.
                            </li>
                            <li className={'pTagsForHomeScreen'}>
                                Open tops of block walls.
                            </li>
                            <li className={'pTagsForHomeScreen'}>
                                Building materials, such as brick, concrete, and rock.
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Bounce>


            <Parallax bgImage={'groupTablePhotoToUse.PNG'} strength={500}>
                <div style={{height: 200}}/>
            </Parallax>

            <br ref={VOCID}/> <br/>

            <div className={"homePageServices"} style={{
                textAlign: 'center',
                padding: '10px 0 40px 0',
                margin: '10px 0 10px 0',
                width: '100%',
                height: 'auto'
            }}>
                <br/>
                <h2 className={"homePageServicesText"}
                    style={{fontWeight: 'bolder', letterSpacing: '.2rem', paddingBottom: '2px'}}>VOC Process</h2>
                <h4 className={"homePageServicesText"}>
                    As a company, Home Safety Solutions(HSS)
                    has completed more than 1,200 vapor intrusion projects. For VOC Mitigation we take these general
                    steps:
                    <br/><br/>
                    Click below to learn more
                </h4>
                <br/>
                <br/>
                <br/>
                <p className={"homePageServicesText"}>
                    Click on a step to find out more
                </p>
                <Row style={{padding: '0', margin: '0', background: '#201c1c'}}>
                    <Col>
                        <Button onClick={handleStep1}>Click to find out more</Button>
                    </Col>

                </Row>

                <br/> <br/>

                {step1 && (
                    <div style={{padding: '10px'}}>
                        <Row>
                            <Col>
                                <h4 className={"homePageServicesText"}>Site Walk And Review</h4>
                                <p className={"homePageServicesText"}>
                                    Initial site walk to review site with building occupant and explain likely
                                    configuration and system options. Listen to owners concerns for potential VOC
                                    exposure.
                                </p>
                            </Col>
                            <Col>
                                <img
                                    src={'siteWalk.jpeg'}
                                    alt={'Buildings'}
                                    className={"landingPagePics"}
                                    loading={"lazy"}
                                    // style={{display: 'block', marginLeft: 'auto', marginRight: "auto",  maxHeight: '260px', maxWidth: '425px', width: 'auto', height: 'auto', borderRadius: '25px'}}
                                />
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <img
                                    src={'7th.JPG'}
                                    alt={'Buildings'}
                                    className={"landingPagePics"}
                                    loading={"lazy"}
                                    // style={{display: 'block', marginLeft: 'auto', marginRight: "auto",  maxHeight: '260px', maxWidth: '425px', width: 'auto', height: 'auto', borderRadius: '25px'}}
                                />
                            </Col>
                            <Col>
                                <h4 className={"homePageServicesText"}>Pre-Mitigation Diagnostics</h4>
                                <p className={"homePageServicesText"}>
                                    Perform Pre-mitigation diagnostics and layout of mitigation design. This can
                                    include: sub-slab depressurization testing and working with engineering firms to
                                    best cover the area of influence required to mitigate.
                                </p>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <h4 className={"homePageServicesText"}>System Installation</h4>
                                <p className={"homePageServicesText"}>
                                    Installation and balance of system as needed to optimize performance. We then ensure
                                    system is running optimally.
                                    We also work with building owners/residents to ensure installation of the system
                                    does not effect the residents.
                                </p>
                            </Col>
                            <Col>
                                <img
                                    src={'instalation.jpeg'}
                                    alt={'Buildings'}
                                    className={"landingPagePics"}
                                    loading={"lazy"}
                                    // style={{display: 'block', marginLeft: 'auto', marginRight: "auto",  maxHeight: '260px', maxWidth: '425px', width: 'auto', height: 'auto', borderRadius: '25px'}}
                                />
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <img
                                    src={'vocpostmit.jpeg'}
                                    alt={'Buildings'}
                                    className={"landingPagePics"}
                                    loading={"lazy"}
                                    // style={{display: 'block', marginLeft: 'auto', marginRight: "auto",  maxHeight: '260px', maxWidth: '425px', width: 'auto', height: 'auto', borderRadius: '25px'}}
                                />
                            </Col>
                            <Col>
                                <h4 className={"homePageServicesText"}>Post Mitigation Diagnostics</h4>
                                <p className={"homePageServicesText"}>
                                    Post mitigation diagnostics pressure field extension readings and performance
                                    adjustments.
                                    After adjustments are made, we continue to monitor the system readings.

                                </p>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <h4 className={"homePageServicesText"}>Maintenance Provided</h4>
                                <p className={"homePageServicesText"}>
                                    We complete and provide operations and maintenance manual as well as "as-built"
                                    system designs.
                                    We ensure our customers have knowledge of how the systems works and we stand by our
                                    system,
                                    providing maintenance as needed to keep it running optimally.
                                </p>
                            </Col>
                            <Col>
                                <img
                                    src={'PostMitigation.PNG'}
                                    alt={'Buildings'}
                                    className={"landingPagePics"}
                                    loading={"lazy"}
                                    // style={{display: 'block', marginLeft: 'auto', marginRight: "auto",  maxHeight: '260px', maxWidth: '425px', width: 'auto', height: 'auto', borderRadius: '25px'}}
                                />
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <img
                                    src={'newFinalStep.jpeg'}
                                    alt={'Buildings'}
                                    className={"landingPagePics"}
                                    // style={{display: 'block', marginLeft: 'auto', marginRight: "auto",  maxHeight: '260px', maxWidth: '425px', width: 'auto', height: 'auto', borderRadius: '25px'}}
                                    loading={"lazy"}
                                />
                            </Col>
                            <Col>
                                <h4 className={"homePageServicesText"}>Final Inspection</h4>
                                <p className={"homePageServicesText"}>
                                    We complete a final inspection and/or paperwork for local and state agencies as
                                    required. We also preform follow up tests as needed and required to ensure
                                    mitigation efficiency.
                                </p>
                            </Col>
                        </Row>
                    </div>
                )}

                <br/><br/>
            </div>

            <br/><br/>

            <Bounce right>
                <Row className={'homePageRow'}
                     style={{textAlign: 'center', borderTop: '3px solid', borderBottom: '3px solid'}}>
                    <Col style={{paddingTop: '30px'}}>

                        <h4>
                            Health effects of VOC exposure
                        </h4>


                        <div className={'homePageServices'} style={{padding: '10px 0', borderRadius: '60px'}}>
                            <img
                                src={'vocExposureIcon.png'}
                                alt={'Buildings'}
                                style={{height: '150px', width: 'auto', borderRadius: '60px', background: 'white'}}
                                loading={"lazy"}
                            />
                        </div>

                        <br/> <br/>
                        <p className={'pTagsForHomeScreen'}>
                            Exposure to VOC vapors can cause a variety of health effects, including eye, nose, and
                            throat irritation.
                            In serious or prolonged exposure headaches and loss of coordination; nausea; and damage to
                            the liver, kidneys, or central nervous system can occur.
                        </p>
                    </Col>

                    <Col style={{paddingTop: '30px'}}>
                        <h4>
                            Volatile Organic Compounds in Your Home
                        </h4>

                        <div className={'homePageServices'} style={{padding: '10px 0', borderRadius: '60px'}}>
                            <img
                                src={'vocInTheHome.PNG'}
                                alt={'Buildings'}
                                style={{height: '150px', width: 'auto', borderRadius: '60px', background: 'white'}}
                                loading={"lazy"}
                            />
                        </div>

                        <br/> <br/>
                        <p className={'pTagsForHomeScreen'}>
                            Volatile Organic Compounds (VOCs) are a large group of chemicals that are found in many
                            products we use to build and maintain our homes.
                            Once these chemicals are in our homes, they are released or “off-gas” into the indoor air we
                            breathe.
                        </p>
                    </Col>

                    <Col style={{paddingTop: '30px'}}>
                        <h4>
                            Sources of VOCs
                        </h4>
                        <br/>

                        <div className={'homePageServices'} style={{padding: '10px 0', borderRadius: '60px'}}>
                            <img
                                src={'VOCHomeEntery.PNG'}
                                alt={'Buildings'}
                                style={{height: '150px', width: 'auto', borderRadius: '60px', background: 'white'}}
                                loading={"lazy"}
                            />
                        </div>
                        <br/> <br/>
                        <p className={'pTagsForHomeScreen'}>
                            Supplies of unused chemicals, such as paints, varnishes, solvents, adhesives and caulks can
                            contain compounds that off-gas voc.
                            Household furnishings like carpet, upholstered furniture or items made from composite wood
                            tend to off-gas more VOCs when they are new.
                        </p>

                    </Col>
                </Row>
            </Bounce>

            <br ref={Mold}/>
            <br/>

            <div className={"moldRemoval"} style={{
                textAlign: 'center',
                padding: '10px 0 40px 0',
                margin: '10px 0 10px 0',
                width: '100%',
                height: 'auto'
            }}>
                <br/>
                <h2 className={"homePageServicesText"}
                    style={{fontWeight: 'bolder', letterSpacing: '.2rem', paddingBottom: '2px'}}>
                    Mold Testing & Remediation
                </h2>
                <h4 className={"homePageServicesText"}>
                    As a company, Home Safety Solutions (HSS) takes these general steps in
                    Mold Remediation: Testing | Remediation | Follow Up
                    Testing
                    <br/><br/>
                    Click below to learn more
                </h4>
                <br/>
                <br/>
                <Button onClick={showMold}>Mold Process Steps</Button>
                <br/>
                <br/>
                <br/>
                {moldSteps && (
                    <div>
                        <Row>
                            <Col>
                                <h4 className={"homePageServicesText"}>Testing</h4>
                                <p className={"homePageServicesText"}>
                                    We assess the interior and exterior of the building for signs of mold. If necessary,
                                    samples of the air, walls, and other surfaces will be taken to test for mold spores
                                    which will be
                                    analyzed in a laboratory and provide a report of the findings.
                                </p>
                            </Col>
                            <Col>
                                <img
                                    src={'moldTesting.PNG'}
                                    alt={'Buildings'}
                                    className={"landingPagePics"}
                                    loading={"lazy"}
                                    // style={{display: 'block', marginLeft: 'auto', marginRight: "auto",  maxHeight: '260px', maxWidth: '425px', width: 'auto', height: 'auto', borderRadius: '25px'}}
                                />
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <img
                                    src={'moldRemoval.PNG'}
                                    alt={'Buildings'}
                                    className={"landingPagePics"}
                                    loading={"lazy"}
                                    // style={{display: 'block', marginLeft: 'auto', marginRight: "auto",  maxHeight: '260px', maxWidth: '425px', width: 'auto', height: 'auto', borderRadius: '25px'}}
                                />
                            </Col>
                            <Col>
                                <h4 className={"homePageServicesText"}>Remediation</h4>
                                <p className={"homePageServicesText"}>
                                    We start by isolating the affected area: Put up containment barriers to separate the
                                    affected area from the rest of the building to contain any mold spores that may
                                    be released during the remediation process.

                                    We then carefully remove any materials that are contaminated
                                    with mold then thoroughly clean and disinfect everything.
                                </p>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <h4 className={"homePageServicesText"}>Follow Up</h4>
                                <p className={"homePageServicesText"}>
                                    After completing remediation we will do follow up testing and monitoring to ensure
                                    the mold does not return.
                                </p>
                            </Col>
                            <Col>
                                <img
                                    src={'moldFollowUp.PNG'}
                                    alt={'Buildings'}
                                    className={"landingPagePics"}
                                    loading={"lazy"}
                                    // style={{display: 'block', marginLeft: 'auto', marginRight: "auto",  maxHeight: '260px', maxWidth: '425px', width: 'auto', height: 'auto', borderRadius: '25px'}}
                                />
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
            <br/>
            <br/>

            <Bounce bottom>
                <Row className={'homePageRow'}
                     style={{textAlign: 'center', borderTop: '3px solid', borderBottom: '3px solid'}}>
                    <Col style={{paddingTop: '30px'}}>

                        <h4>
                            Health effects of Mold exposure
                        </h4>

                        <div className={'moldRemoval'} style={{padding: '10px 0', borderRadius: '60px'}}>
                            <img
                                src={'moldHealthIcon.PNG'}
                                alt={'Buildings'}
                                style={{height: '150px', width: 'auto', borderRadius: '60px', background: 'white'}}
                                loading={"lazy"}
                            />
                        </div>

                        <br/> <br/>
                        <p className={'pTagsForHomeScreen'}>
                            Exposure to a large number of mold spores may cause allergic symptoms such as watery eyes,
                            runny nose, sneezing, itching, coughing, wheezing, difficulty breathing, headache, and
                            fatigue.
                            Repeated exposure to mold can increase a person's sensitivity, causing more severe allergic
                            reactions.
                        </p>
                    </Col>

                    <Col style={{paddingTop: '30px'}}>
                        <h4>
                            Mold in Your Home
                        </h4>
                        <br/>
                        <div className={'moldRemoval'} style={{padding: '10px 0', borderRadius: '60px'}}>
                            <img
                                src={'moldInTheHome.jpg'}
                                alt={'Buildings'}
                                style={{height: '150px', width: 'auto', borderRadius: '60px', background: 'white'}}
                                loading={"lazy"}
                            />
                        </div>

                        <br/> <br/>
                        <p className={'pTagsForHomeScreen'}>
                            Mold will grow in places with a lot of moisture, such as around leaks in roofs, windows, or
                            pipes,
                            or where there has been flooding. Mold grows well on paper products, cardboard, ceiling
                            tiles,
                            and wood products. Mold can also grow in dust, paints, wallpaper, insulation, drywall,
                            carpet, fabric, and upholstery.
                        </p>
                    </Col>

                    <Col style={{paddingTop: '30px'}}>
                        <h4>
                            Sources of Mold buildings
                        </h4>
                        <br/>

                        <div className={'moldRemoval'} style={{padding: '10px 0', borderRadius: '60px'}}>
                            <img
                                src={'moldEntery.PNG'}
                                alt={'Buildings'}
                                style={{height: '150px', width: 'auto', borderRadius: '60px', background: 'white'}}
                                loading={"lazy"}
                            />
                        </div>
                        <br/> <br/>
                        <p style={{textAlign: "left"}} className={'pTagsForHomeScreen'}>
                            ⚫ Persistent Humidity <br/>
                            ⚫ Leaking Pipes in the Home <br/>
                            ⚫ A Leaking Roof <br/>
                            ⚫ A Build-Up of Condensation <br/>
                            ⚫ Poor Ventilation in Your Home <br/>
                            ⚫ Home Flooding <br/>
                            ⚫ A Damp Basement <br/>
                            ⚫ Foundation Dampness <br/>
                            ⚫ A Leaking Air-Conditioning System <br/>
                        </p>

                    </Col>
                </Row>
            </Bounce>

            <br ref={QualifcationsID}/> <br/>


            <Parallax bgImage={'qualificationsBackgroundIcon.PNG'} strength={500}>
                <div style={{height: 80}}/>

                <Row style={{backgroundColor: 'black', padding: '40px 0', margin: '40px', opacity: '80%'}}>
                    <h2 style={{
                        textAlign: 'center',
                        backgroundColor: 'black',
                        color: 'white',
                        padding: '40px 0'
                    }}>MITIGATION & QUALIFICATIONS</h2>
                    <br/> <br/>
                    <Col style={{textAlign: 'left', fontWeight: 'bold', borderRight: 'solid', borderColor: 'white'}}>
                        <h4 style={{color: 'white'}}>
                            What We Have Done
                        </h4>
                        <br/>
                        <p style={{color: 'white', fontSize: '1rem'}}>
                            In the past 15 years, HSS has completed chemical vapor intrusion mitigation systems at more
                            than
                            500 single-family
                            homes and more then 650 commercial sites that include school's and daycare's. HSS has
                            primarily mitigated
                            sites affected by TCE, PCE, Methane, Fuel Oil, and Benzene.
                            <br/>
                            We have:
                            <br/>
                            ⚪ 30 years construction experience
                            <br/>
                            ⚪ 30 years Real Estate experience
                            <br/>
                            ⚪ 10 years Home Inspection business
                            <br/>
                            ⚪ 10 years experience in multi-family ownership and responsibility
                            <br/>
                            Professional Affiliations:
                            <br/>
                            ⚪ MHA (MN Housing Association)
                            <br/>
                            ⚪ AARST (American Association of Radon Scientists and Technologists
                            <br/>
                            ⚪ Indoor Air Quality Association Inc.
                            <br/>
                            ⚪ MSHI (MN Society of Housing Inspections)
                            <br/>
                            ⚪ National Association of Realtors
                            <br/>
                            ⚪ NEHA (National Environmental Health Association
                        </p>

                    </Col>
                    <Col style={{textAlign: 'right', fontWeight: 'bold'}}>
                        <h4 style={{color: 'white'}}>
                            Our Qualifications
                        </h4>
                        <br/>
                        <p style={{color: 'white', fontSize: '1rem'}}>
                            Home Safety Solutions Inc. has been certified in radon mitigation and testing since 2007
                            through AARST-NRPP.
                            We hold certifications in all areas for radon mitigation and testing
                            such as
                            residential, commercial, multi-family, schools, and new construction. This extensive work in
                            both radon and VOC mitigation,
                            as well as radon testing, has given the staff of HSS extensive knowledge for the design and
                            installation of
                            SSDS & SMDS systems in any building type or configuration. We also hold certification for
                            Mold &
                            Water intrusion Surveyor and Measurement Technicians. Whatever the issue, we have proven
                            through
                            experience and certification we can handle it. We work with multifamily residences, single
                            family residences,
                            and commercial properties. Realtors selling homes with indoor air quality issues and anyone
                            who needs our help and expertise with radon testing & mitigation, and Mold/Water intrusion
                            measurement and remediation.
                        </p>
                    </Col>
                </Row>

                <div style={{height: 80}}/>
            </Parallax>


            <br/><br/>

            <h3 style={{textAlign: 'center'}}>You Do Not Just Have To Take Our Word For It</h3>
            <br/>
            <Carousel className={'testimonialsCarousel'} style={{height: '60%'}}>
                <Carousel.Item>
                    <Row>
                        <Col style={{textAlign: 'left'}}>
                            <h3>
                                Peggy
                            </h3>
                            <p className={'pTagsForHomeScreen'}>
                                “WORKED HARD, VERY NICE WORK. ANSWERED ALL MY QUESTIONS.”
                            </p>
                        </Col>
                        <Col style={{textAlign: 'right'}}>
                            <h3>
                                Miluska
                            </h3>
                            <p className={'pTagsForHomeScreen'}>
                                “HSS' COLLABORATION AND FANTASTIC WORK IS MUCH APPRECIATED AS THE COMPANY WENT BEYOND
                                THE CALL OF DUTY AT EVERY STEP.”
                            </p>
                        </Col>
                    </Row>
                </Carousel.Item>
                <Carousel.Item>
                    <Row>
                        <Col style={{textAlign: 'left'}}>
                            <h3>
                                Chesloy
                            </h3>
                            <p className={'pTagsForHomeScreen'}>
                                “AS ALWAYS THANKS SO MUCH FOR YOUR HELP, YOUR GUYS WERE GREAT!”
                            </p>
                        </Col>
                        <Col style={{textAlign: 'right'}}>
                            <h3>
                                Carolyn
                            </h3>
                            <p className={'pTagsForHomeScreen'}>
                                “THANK YOU SO MUCH FOR SUCH A GREAT JOB - YOU’D HARDLY EVEN NOTICE IT’S THERE IF YOU
                                DIDN’T KNOW WHERE TO LOOK.” I APPRECIATE HOW QUICKLY EVERYONE WORKED TO GET THIS DONE
                                AND RETESTED. I WILL HIGHLY RECOMMEND YOUR COMPANY TO ANYONE WHO NEEDS TO GET ONE
                                OF THESE INSTALLED IN THE FUTURE.”
                            </p>
                        </Col>
                    </Row>
                </Carousel.Item>
                <Carousel.Item>
                    <Row>
                        <Col style={{textAlign: 'left'}}>
                            <h3>
                                Terri
                            </h3>
                            <p className={'pTagsForHomeScreen'}>
                                “I JUST WANT TO THANK YOU FOR WORKING SO WELL WITH MY PARENTS. YOU DID RIGHT BY THEM
                                AND IT DID NOT GO UNNOTICED! YOU ARE AWESOME!”
                            </p>
                        </Col>
                        <Col style={{textAlign: 'right'}}>
                            <h3>
                                Vera Cruz Ave. N.
                            </h3>
                            <p className={'pTagsForHomeScreen'}>
                                “VERY IMPRESSED WITH THE METHOD OF INSTALLATION AND EFFORTS TO MINIMIZE THE DUST AND
                                DEBRIS WHEN CUTTING THROUGH THE CEMENT BLOCKS. LEFT WORK SITE CLEAN.”
                            </p>
                        </Col>
                    </Row>
                </Carousel.Item>
                <Carousel.Item>
                    <Row>
                        <Col style={{textAlign: 'left'}}>
                            <h3>
                                Juliet Ave.
                            </h3>
                            <p className={'pTagsForHomeScreen'}>
                                “VERY SATISFIED WITH RESULTS! EVERY EFFORT WAS MADE TO HIDE THE PIPING & EVERYTHING WAS
                                FINISHED OFF VERY WELL. I WOULD RECOMMEND YOU TO FRIENDS & FAMILY. THANKS!”
                            </p>
                        </Col>
                        <Col style={{textAlign: 'right'}}>
                            <h3>
                                Jason & Molly Larson
                            </h3>
                            <p className={'pTagsForHomeScreen'}>
                                “WE WERE EXTREMELY HAPPY WITH THE PROMPT AND PROFESSIONAL SERVICE WE RECEIVED.
                                THANK YOU!”
                            </p>
                        </Col>
                    </Row>
                </Carousel.Item>
            </Carousel>

            <br/> <br/> <br/>
            <div ref={myRef}>
                <Footer/>
            </div>
        </Container>
    )
}

export default HomeScreen;
